import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import { Button } from "@material-ui/core";

const LeyendaEtapa = () => {
  const [showLeyenda, setShowLeyenda] = useState(false);
  return (
    <React.Fragment>
      <Button
        color="primary"
        variant="outlined"
        onClick={(e) => setShowLeyenda(!showLeyenda)}
        className="mb-1"
      >
        Leyenda
      </Button>
      <br/>
      {showLeyenda && (
        
        <div className="alert alert-info mt-3">
          <Chip label={"POSTULACIÓN"} className="m-1" />{" "}
          <span>El cupo se ha publicado y esta abierto para que el público pueda postular.</span> <br />
          <Chip label={"FILTRO CURRICULAR"} color="info" className="m-1" />{" "}
          <span>El cupo ha sido cerrado y se estan revisando sus antecedentes.</span> <br />
          <Chip label={"EXÁMEN TÉCNICO"} color="primary" className="m-1" />{" "}
          <span>
            Etapa en la cual se realizará un exámen de conocimientos técnicos atingentes al área que está postulando.
          </span>{" "}
          <br />
          <Chip label={"VALORACION GLOBAL"} color="success" className="m-1" />{" "}
          <span>Etapa en la que se realiza una evaluación personal por medio de una entrevista</span>{" "}
          <br />
          <Chip label={"PSICOLABORAL"} color="error" className="m-1" />{" "}
          <span>
            Etapa en la cual se realiza un exámen psicológico.
          </span>{" "}
          <br />
          <Chip label={"REGLAMENTARIA"} color="warning" className="m-1" />{" "}
          <span>
            Se realizan exámenes médicos y de antecedentes personales.
          </span>{" "}
          <br/>
          <Chip label={"CONTRATO"} color="warning" className="m-1" />{" "}
          <span>
            Su puesto esta en proceso de elaboración del contrato de trabajo.
          </span>{" "}
          <br />
        </div>
      )}
    </React.Fragment>
  );
};

export default LeyendaEtapa;