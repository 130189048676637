import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import {
  AntecedentesPersonales
} from "./AntecedentesPersonales";
import AddLaboral from "./AddLaboral";
import AddDocumentacion from "./AddDocumentacion";
import axios from "axios";
import variables from "../../variables.json";
import { useGlobalContext } from "../../context/ContextGlobal";

import TableAcademico from "./TableAcademico";
import TableLaboral from "./TableLaboral";
import AddAcademico from "./AddAcademico";
import NuevaCuenta from "./NuevaCuenta";
import AddConocimiento from "./AddConocimiento";
import TableConocimiento from "./TableConocimiento";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function VerticalLinearStepper() {
  const { setLoad, alertDialog } = useGlobalContext();
  const [conocimiento, setConocimiento] = useState([]);
  const [antecedentesAcademicos, setAntecedentesAcademicos] = useState([]);
  const [antecedentesLaborales, setAntecedentesLaborales] = useState([]);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [values, setValues] = React.useState({
    rut: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    region: 0,
    comuna: 0,
    sexo: "",
    discapacidad: "",
    direccion: "",
    telefono: "",
    fechaNacimiento: new Date(),
    estadoCivil: 0,
    correo: "",
    estado: 0,
    password: "",
    repassword: "",
    discapacidad: "",
    showPassword: false,
    showRePassword: false,
    idGuid: "",
    documentacion: null,
    funcionario: 0,
    familiar: 0,
  });
  
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const createData = async(file) => {
    console.log(values);
    const formData = new FormData();
    formData.append("correo", values.correo);
    formData.append("password", values.password);
    formData.append("Rut", values.rut);
    formData.append("Nombres", values.nombres);
    formData.append("ApellidoPaterno", values.apellidoPaterno);
    formData.append("ApellidoMaterno", values.apellidoMaterno);
    formData.append("Region", values.region);
    formData.append("Comuna", values.comuna);
    formData.append("Direccion", values.direccion);
    formData.append("EstadoCivil", parseInt(values.estadoCivil));
    formData.append("Discapacidad", values.discapacidad);
    formData.append("Sexo", values.sexo);
    formData.append("Telefono", values.telefono);
    formData.append("FechaNacimiento", values.fechaNacimiento);
    formData.append("Familiar", values.familiar);
    formData.append("Funcionario", values.funcionario);
    formData.append("Nacionalidad", values.nacionalidad);
    await formData.append("Documento", file);
    await sleep(2000);
    return  formData;
  }

  const sendData = async(file) => {
    var token = sessionStorage.getItem('token');
    setLoad(true);
    let formData = await createData(file);
    axios
      .post(variables.api + "/api/Cuenta/Create", formData, { headers: {"Authorization" : `Bearer ${token}`} })
      .then((response) => {
        if (response.data === "rut") {
          alertDialog(
            "Error",
            "Este RUT ya se encuentra registrado, intente recuperar su contraseña",
            "error"
          );
        } else if (response.data === "correo") {
          alertDialog(
            "Error",
            "Este Correo ya se encuentra registrado, intente recuperar su contraseña",
            "error"
          );
        } else if (response.data === "error") {
          alertDialog(
            "Error",
            "No se pudo completar la acción, se produjo un error interno, intenteló nuevamente",
            "error"
          );
        } else {

          const antecedentes = {
            cuenta : response.data,
            antecedentesLaborales: antecedentesLaborales,
            antecedentesAcademicos: antecedentesAcademicos,
            conocimientos: conocimiento
          }
          axios.post(variables.api + "/api/Antecedentes",antecedentes, { headers: {"Authorization" : `Bearer ${token}`} }).then(response => {
            setLoad(false);
            alertDialog("Completada", "Su cuenta ha sido creada", "success");
          }).catch((error) => {
            setLoad(false);
          }).finally(() => setLoad(false));
          
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => setLoad(false));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

 

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Crear una contraseña</StepLabel>
          <StepContent>
            <NuevaCuenta
              values={values}
              setValues={setValues}
              activeStep={activeStep}
              handleNext={handleNext}
            ></NuevaCuenta>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Antecedentes Personales</StepLabel>
          <StepContent>
            <AntecedentesPersonales
              values={values}
              setValues={setValues}
              handleBack={handleBack}
              activeStep={activeStep}
              handleNext={handleNext}
            ></AntecedentesPersonales>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Antecedentes Academicos</StepLabel>
          <StepContent>
            <AddAcademico
              setAntecedentesAcademicos={setAntecedentesAcademicos}
              antecedentesAcademicos={antecedentesAcademicos}
            ></AddAcademico>
            <TableAcademico
              handleNext={handleNext}
              setAntecedentesAcademicos={setAntecedentesAcademicos}
              antecedentesAcademicos={antecedentesAcademicos}
            ></TableAcademico>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Antecedentes Laborales</StepLabel>
          <StepContent>
            <AddLaboral
              setAntecedentesLaborales={setAntecedentesLaborales}
            ></AddLaboral>
            <TableLaboral
              handleNext={handleNext}
              setAntecedentesLaborales={setAntecedentesLaborales}
              antecedentesLaborales={antecedentesLaborales}
            ></TableLaboral>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Conocimientos</StepLabel>
          <StepContent>
            <AddConocimiento
              setConocimiento={setConocimiento}
            ></AddConocimiento>
            <TableConocimiento
              handleNext={handleNext}
              setConocimiento={setConocimiento}
              conocimiento={conocimiento}
            ></TableConocimiento>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Documentación</StepLabel>
          <StepContent>
            <AddDocumentacion
              sendData={sendData}
              handleNext={handleNext}
              values={values}
              setValues={setValues}
            ></AddDocumentacion>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}
