import React from "react";
import "./estilos.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import variables from "../../variables.json";
import { useGlobalContext } from "../../context/ContextGlobal";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";


const Index2 = () => {

    function handleGoogleResponse(response) {
        console.log(response);
    }


    const { setLoad, alertDialog, setSessionUser, setLoged } = useGlobalContext();
    let history = useHistory();
    //inicializacion del hook para trabajar con formularios
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    //autentificacion de usuario mediante active directory
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("user", data.user);
        formData.append("pass", data.password);


        setLoad(true);
        var token = sessionStorage.getItem("token");
        axios
            .post(variables.api + "/api/Login", formData, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setSessionUser(response.data);
                setLoged(true);
                alertDialog("Bienvenido postulante", "", "success");
                history.push("/");
            })
            .catch((e) => {
                alertDialog("Error", "Usuario o contraseña incorrecta", "error");

            });

        setLoad(false);
    };

    const loginGoogle = () => {
        window.open(variables.api + "/api/Auth/google-login");
    }

    return (
        <div className="row">
            <div className="col-12 col-lg-4 col-md-6">
                <div className="card">
                    <div className="card-body card-login">
                        <center>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-center">Inicio de sesión</h3>
                                <input
                                    className="form-control m-2"
                                    required
                                    type="text"
                                    placeholder="Ingrese su correo electrónico"
                                    {...register("user")}
                                />
                                <input
                                    className="form-control m-2"
                                    required
                                    type="password"
                                    {...register("password")}
                                />
                                <input
                                    type="submit"
                                    className="btn btn-primary"
                                    value="Ingresar"
                                />
                                <br />
                                <Link
                                    to="lostaccount"
                                    onClick={(e) => history.push("/lostaccount")}
                                >
                                    Olvide mi contraseña
                                </Link>
                            </form>
                            <button onClick={() => loginGoogle()}>Ingresar con google</button>

                        </center>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-8 col-md-6">
                <div className="m-5">
                    <center>
                        <h1>Postulaciones PDI</h1>
                    </center>

                    <h4>
                        Para acceder en el proceso de selección a un cargo de Policía de
                        Investigaciones, deberá contar con una cuenta, la cual le permitirá
                        realizar el seguimiento de su postulación.
                    </h4>
                    <center className="mt-5">
                        <Link to="/NewAccount" className="btn btn-secondary">
                            Registrar una cuenta
                        </Link>
                    </center>
                </div>
            </div>
        </div>
    );
};

export default Index2;