import React, {useEffect, useState} from 'react';
import axios from 'axios';
import variables from '../variables.json';

const PerfilCargoDialog = ({data}) => {
    const [archivo, setArchivo] = useState(null);
    useEffect(() => {
        var token = sessionStorage.getItem('token');
        axios.get(variables.api + "/api/Proceso/GetFile/"+data,  { headers: {"Authorization" : `Bearer ${token}`} }).then(e => {
                console.log(e);
                setArchivo("data:application/pdf;base64," + e.data);    
            }
        )
        
    }, [])
    return (      
                archivo?
                <embed
                src={archivo}
                type="application/pdf"
                width="100%"
                height="100%"
              />:null
    )
}

export default PerfilCargoDialog
