import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";

const AddLaboral = ({setAntecedentesLaborales}) => {
  const { handleSubmit, control } = useForm();
  const [open, setOpen] = React.useState(false);
  const [cuenta, setCuenta] = React.useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async(data) => {
    //campo a evaluar data.hasta y data.desde
    //validar fecha que no sea mayor que hoy
    //fecha desde que sea menor a fecha hasta.

    data.id = cuenta;
    await setAntecedentesLaborales((prevState) => [...prevState, data]);
    await setCuenta(cuenta + 1);
    handleClose();
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        + Agregar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            Agregar Antecedentes Laborales
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingrese los campos requeridos a continuación, por cada experiencia
              laboral que desee informar.
            </DialogContentText>

            <Controller
              name="empresa"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  className="m-2 form-control"
                  label="Empresa"
                  value={value}
                  onChange={onChange}
                  required
                />
              )}
            />




            <Controller
              name="desde"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className="m-2 form-control"
                  label="Inicio"
                  type="month"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              )}
            />

            <Controller
              name="hasta"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className="m-2 form-control"
                  label="Termino"
                  type="month"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              )}
            />

            <Controller
              name="descripcionCargo"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="outlined-textarea"
                  onChange={onChange}
                  value={value}
                  className="m-2 form-control"
                  label="Descripción del cargo"
                  type="text"
                  minRows={3}
                  multiline
                  required
                />
              )}
            />

            <Controller
              name="referencia"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className="m-2 mt-5 col-12"
                  label="Referencia"
                  type="text"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Grabar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddLaboral;
