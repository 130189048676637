import React, { useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useGlobalContext } from '../context/ContextGlobal';
import logoPdi from '../assets/images/logo2.png'


const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
 
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }
    const { loged } = useGlobalContext();
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 fondo" light>
          <Container>
            <NavbarBrand tag={Link} to="/"><img src={logoPdi} height="50px" alt="" /></NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="" to="/">Inicio</NavLink>
                </NavItem>
                {
                  loged &&
                  <React.Fragment>
                    <NavItem>
                      <NavLink tag={Link}  to="/misPostulaciones">Mis Postulaciones</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link}  to="/examenes">Exámenes</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link}  to="/miCuenta">Mi cuenta</NavLink>
                    </NavItem>
                  </React.Fragment>
                }
                
                <NavItem>
                  {
                    loged ? 
                    <NavLink tag={Link}  to="/logout">Salir</NavLink>
                    :
                    <NavLink tag={Link}  to="/login">Ingresar</NavLink>
                  }
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
}

export default NavMenu;
