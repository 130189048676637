import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/ContextGlobal";
import variables from "../../variables.json";
import { useAlert } from "react-alert";

const ModificaPersonales = () => {
  const [datosPersonales, setDatosPersonales] = useState();
  const { alertDialog } = useGlobalContext();
  const [regiones, setRegiones] = useState();
  const [comunas, setComunas] = useState();
  const [errors, setErrors] = useState({});
  const alert = useAlert();

  const errorStyle = {
    background: "pink",
    borderWidth: 1,
    borderColor: "Red",
  };
  const inputErrorStyle = {
    color: "red",
  };

  useEffect(() => {
    const usuario = sessionStorage.getItem("usuario");
    var token = sessionStorage.getItem('token');
    axios
      .get(variables.api + "/api/Cuenta/CuentaIndividual/" + usuario, { headers: {"Authorization" : `Bearer ${token}`} })
      .then((response) => {
        axios
          .get(
            variables.api +
              "/api/Comuna?id=" +
              parseInt(response.data.datosPersonales.region), { headers: {"Authorization" : `Bearer ${token}`} }
          )
          .then((response) => {
            console.log(response);
            setComunas(response.data);
          })
          .catch((e) => {
            alert.error("error");
          });

        setDatosPersonales(response.data.datosPersonales);
      })
      .catch(() => {
        alertDialog(
          "Error",
          "Error al obtener los datos de su cuenta",
          "error"
        );
      });

    axios.get(variables.api + "/api/Region", { headers: {"Authorization" : `Bearer ${token}`} }).then((response) => {
      console.log(response);
      setRegiones(response.data);
    });
  }, []);

  const handleChangeRegion = (r) => {
    var token = sessionStorage.getItem('token');
    axios
      .get(variables.api + "/api/Comuna?id=" + r, { headers: {"Authorization" : `Bearer ${token}`} })
      .then((response) => {
        setComunas(response.data);
        updateValue(r,"region");
        updateValue(response.data[0].idComuna,"comuna")
      })
      .catch((e) => {
        alertDialog("error","error al obtener información de las comunas","error");
      });
  };

  const validarFormulario = () => {
    var token = sessionStorage.getItem('token');
    var error = false;
    for (var x in datosPersonales) {
      if (datosPersonales[x] === null || datosPersonales[x] === "") {
        errors[x] = true;
        error = true;
      }
    }
    if (!error) {
    
      var correoV = datosPersonales.correo;
      const formData = new FormData();
      formData.append("Correo", datosPersonales.correo);
      formData.append("Id", datosPersonales.id);
      formData.append("Rut", datosPersonales.rut);
      formData.append("Nombres", datosPersonales.nombres);
      formData.append("ApellidoPaterno", datosPersonales.apellidoPaterno);
      formData.append("ApellidoMaterno", datosPersonales.apellidoMaterno);
      formData.append("Region", parseInt(datosPersonales.region));
      formData.append("Comuna", parseInt(datosPersonales.comuna));
      formData.append("Direccion", datosPersonales.direccion);
      formData.append("EstadoCivil", parseInt(datosPersonales.estadoCivil));
      formData.append("Discapacidad", datosPersonales.discapacidad);
      formData.append("Sexo", datosPersonales.sexo);
      formData.append("Telefono", datosPersonales.telefono);
      formData.append("FechaNacimiento", datosPersonales.fechaNacimiento);

    

      axios
        .patch(variables.api + "/api/Cuenta/Update", formData, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(() => alertDialog("Cuenta actualizada","recuerde que debe volver a postular al cargo para que sus datos se vean reflejados","success"))
        .catch(() => alertDialog("error","Error al actualizar su cuenta, intente nuevamente","error"));
    }
  };

  const handleUpdate = () => {
    validarFormulario();
  };

  const updateValue = (e, campo) => {
    console.log(campo, "valor: ", e)
    errors[campo] = false;
    setDatosPersonales((prevState) => ({
      ...prevState,
      [campo]: String(e).toUpperCase(),
    }));
  };

  return (
    <div>
      
      {datosPersonales && (
        <React.Fragment>
          <hr />
          <center>

          <h4>Datos Personales</h4>
          </center>
          <hr />
          <center>
            <div>
              <label className="">RUN</label>
              <input
                type="text"
                className="form-control col-12 col-lg-3 col-md-8"
                disabled
                value={datosPersonales.rut}
              />
              <label className="">Correo</label>
              <input
                type="text"
                className="form-control col-12 col-lg-3 col-md-8"
                onChange={(e) => updateValue(e.target.value, "correo")}
                value={datosPersonales.correo}
              />
              <label className="">Nombres</label>
              <input
                type="text"
                className="form-control col-12 col-lg-3 col-md-8"
                value={datosPersonales.nombres}
                onChange={(e) => updateValue(e.target.value, "nombres")}
                style={errors.nombres ? errorStyle : null}
              />
              {errors.nombres && (
                <small style={inputErrorStyle}>
                  Debe completar este campo <b>x</b>
                  <br />
                </small>
              )}

              <label className="">Apellido Paterno</label>
              <input
                type="text"
                className="form-control col-12 col-lg-3 col-md-8"
                value={datosPersonales.apellidoPaterno}
                onChange={(e) => updateValue(e.target.value, "apellidoPaterno")}
                style={errors.apellidoPaterno ? errorStyle : null}
              />
              {errors.apellidoPaterno && (
                <small style={inputErrorStyle}>
                  Debe completar este campo <b>x</b>
                  <br />
                </small>
              )}
              <label className="">Apellido Materno</label>
              <input
                type="text"
                className="form-control col-12 col-lg-3 col-md-8"
                value={datosPersonales.apellidoMaterno}
                onChange={(e) => updateValue(e.target.value, "apellidoMaterno")}
                style={errors.apellidoMaterno ? errorStyle : null}
              />
              {errors.apellidoMaterno && (
                <small style={inputErrorStyle}>
                  Debe completar este campo <b>x</b>
                  <br />
                </small>
              )}
              <label htmlFor="">Estado Civil</label>
              <select
                defaultValue={datosPersonales.estadoCivil}
                className="form-control col-12 col-lg-3 col-md-8"
                onChange={(e) => updateValue(e.target.value, "estadoCivil")}
              >
                <option value={1}>Soltero</option>
                <option value={2}>Casado</option>
                <option value={3}>Viudo</option>
                <option value={4}>Unión Civil</option>
                <option value={5}>Separado</option>
              </select>

              <label htmlFor="">Sexo</label>

              <select
                defaultValue={datosPersonales.sexo}
                onChange={(e) => updateValue(e.target.value, "sexo")}
                name=""
                id=""
                className="form-control col-12 col-lg-3 col-md-8"
              >
                <option value="HOMBRE">HOMBRE</option>
                <option value="MUJER">MUJER</option>
              </select>
              <label htmlFor="">Región</label>
              {regiones && (
                <select
                  className="form-control col-12 col-lg-3 col-md-8"
                  defaultValue={datosPersonales.region}
                  onChange={(e) => handleChangeRegion(e.target.value)}
                >
                  {regiones ? (
                    regiones.map((r) => {
                      return (
                        <option value={r.idRegion} key={r.idRegion}>
                          {r.nombre}
                        </option>
                      );
                    })
                  ) : (
                    <option>Cargando ...</option>
                  )}
                </select>
              )}

              <label htmlFor="">Comuna</label>
              {comunas && (
                <select
                  name=""
                  id=""
                  className="form-control col-12 col-lg-3 col-md-8"
                  defaultValue={datosPersonales.comuna}
                  onChange={(e) => updateValue(e.target.value, "comuna")}

                >
                  {comunas ? (
                    comunas.map((r) => {
                      return (
                        <option value={r.idComuna} key={r.idComuna}>
                          {r.nombre}
                        </option>
                      );
                    })
                  ) : (
                    <option>Cargando ...</option>
                  )}
                </select>
              )}

              <label className="">Direccion</label>
              <input
                type="text"
                className="form-control col-12 col-lg-3 col-md-8"
                value={datosPersonales.direccion}
                onChange={(e) => updateValue(e.target.value, "direccion")}
                style={errors.direccion ? errorStyle : null}
              />
              {errors.direccion && (
                <small style={inputErrorStyle}>
                  Debe completar este campo <b>x</b>
                  <br />
                </small>
              )}
              <label className="">Teléfono</label>
              <input
                type="text"
                className="form-control col-12 col-lg-3 col-md-8"
                value={datosPersonales.telefono}
                onChange={(e) => updateValue(e.target.value, "telefono")}
                style={errors.telefono ? errorStyle : null}
              />
              {errors.telefono && (
                <small style={inputErrorStyle}>
                  Debe completar este campo <b>x</b>
                </small>
              )}
            </div>
            <button
              className="btn btn-success m-3"
              onClick={(e) => handleUpdate()}
            >
              Actualizar Información Personal
            </button>
          </center>
          <div className="m-5"></div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ModificaPersonales;
