import React, { useEffect, useState } from "react";
import { DataGrid, esES, GridOverlay } from "@mui/x-data-grid";
import { Button, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const NoData = () => {
  return (
    <GridOverlay>
      <h3>No ha cargado información academica</h3>
    </GridOverlay>
  );
};

export default function DataTable({
  antecedentesAcademicos,
  setAntecedentesAcademicos,
  handleNext
}) {
  useEffect(() => {
    console.log(antecedentesAcademicos);
  }, []);

  const deleteRow = (row) => {
    setAntecedentesAcademicos(
      antecedentesAcademicos.filter((item) => item.id != row.row.id)
    );
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, flex: 0.5, hide: true },
    {
      field: "tipoEstablecimientoNombre",
      headerName: "Tipo de establecimiento",
      flex: 1,
    },
    {
      field: "casaEstudios",
      headerName: "Establecimiento",
      flex: 1,
    },
    {
      field: "nombreTitulo",
      headerName: "Nombre del Título",
      flex: 1,
    },
    {
      field: "tipoTituloNombre",
      headerName: "Nivel de estudio",
      flex: 1,
    },

    {
      field: "fechaObtencion",
      headerName: "Fecha de Obtención",
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Acciones",
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            aria-label="delete"
            onClick={(e) => deleteRow(params)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="mb-3" style={{ height: 400, width: "100%" }}>
        <DataGrid
          localeText={esES.props.MuiDataGrid.localeText}
          rows={antecedentesAcademicos}
          columns={columns}
          pageSize={5}
          components={{
            NoRowsOverlay: NoData,
          }}
        />
      </div>
      <Button
        className="mt-3"
        type="submit"
        variant="contained"
        disabled={antecedentesAcademicos.length == 0}
        color="primary"
        onClick={handleNext}
      >
        Siguiente
      </Button>
    </React.Fragment>
  );
}
