import React, { useEffect } from 'react'
import { useGlobalContext } from '../../context/ContextGlobal';

const Logout = () => {
    const { setLoged } = useGlobalContext();
    useEffect(() => {
        sessionStorage.clear();
        setLoged(false);
    },[])

    return (
        <div>
            <div className="alert alert-info">
                <h3>Te has desconectado</h3>
                <p>Su sesión ha terminado, gracias por participar en nuestros procesos.</p>
            </div>
        </div>
    )
}

export default Logout
