import React, { useEffect, useState } from "react";
import axios from "axios";
import variables from "../../variables.json";
import { useGlobalContext } from "../../context/ContextGlobal";
import Examen from "./Examen";
import Moment from "react-moment";

const IniciarTest = () => {
  const { alertDialog, setLoad } = useGlobalContext();
  const [acceso, setAcceso] = useState();
  const usuario = sessionStorage.getItem("usuario");
  const codigoPrueba = sessionStorage.getItem("prueba");
  const [habilitado , setHabilitado] = useState(false);

  useEffect(() => {
    setLoad(true);
    const data = {
      postulante: usuario,
      prueba: codigoPrueba,
    };
    axios
      .patch(variables.api + "/api/Examen/InicioExamen", data)
      .then((response) => {
        var auxHabilitado = response.data.habilitado;
        auxHabilitado = response.data.suspendido ? false : true;
        auxHabilitado = response.data.finalizaExamen ? false : true;
        setHabilitado(auxHabilitado);
        setAcceso(response.data);
      })
      .catch(() =>
        alertDialog("error", "Se ha producido un error interno", "error")
      )
      .finally(() => setLoad(false));
  }, []);

  return (
    <div className="container-fluid">

      {acceso && acceso.suspendido && (
        <div className="alert alert-danger">
          Usted ha sido suspendido del exámen
        </div>
      )}


      {acceso && acceso.finalizaExamen && (
        <div className="alert alert-danger">
          Usted ha finalizado el exámen
        </div>
      )}


      {acceso && acceso.finalizado && (
        <div className="alert alert-danger">
           El tiempo del exámen a expirado
        </div>
      )}
      
      {habilitado && acceso && acceso.habilitado && (
      
        <React.Fragment>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Hora Inicio</th>
                <th>Hora Termino</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Moment format="HH:mm">{acceso.inicioExamen}</Moment>
                </td>
                <td>
                
                <Moment format="HH:mm">{acceso.expiraExamen}</Moment>
                </td>
              </tr>
            </tbody>
          </table>
          <Examen postulante={usuario}  codigo={codigoPrueba}></Examen>
        </React.Fragment>
      )}

      
    </div>
  );
};

export default IniciarTest;
