import React from 'react'

const Suspendido = () => {
    return (
        <div className='container-fluid'>
            <div className="alert alert-danger">
                <h4>Estimado postulante ha sido suspendido del exámen técnico</h4>
            </div>
        </div>
      )
}

export default Suspendido