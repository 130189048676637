import React from 'react'

const Expira = () => {
    return (
        <div className='container-fluid'>
            <div className="alert alert-danger">
                <h4>Estimado postulante ha expirado el tiempo de su exámen técnico, será notificado mediante correo y por la plataforma de los resultados de esta etapa</h4>
            </div>
        </div>
      )
}

export default Expira