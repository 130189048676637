import React, { useState, useEffect} from 'react';
import axios from 'axios';
import variables from '../../variables.json';


const Temario = ({data, onClose = () => {}}) => {
    const [archivo, setArchivo] = useState();
    const usuario = sessionStorage.getItem("usuario");
    useEffect(() => {
        var token = sessionStorage.getItem('token');
        axios.get(variables.api + "/api/examen/BajarTemario/"+data+"/"+usuario, { headers: {"Authorization" : `Bearer ${token}`} }).then(e => {
             
                setArchivo("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + e.data);    
                window.open("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"+ e.data,'blank');
            }
        ).catch(() => {
            console.log("Error","Error de comunicación con la base de datos, no se pudo obtener el Temario del examen","error")
        })

        
    }, [data])


    

    return (    
   
        archivo?
                <>
                <div></div>
           
                {/*<iframe
                src={archivo}
                type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"

                width="100%"height="100%"
                
                />*/}

                {/*<button onClick={() => onClose() }>Cerrar</button>*/}

            </>:null
    
        
            
    )
}

export default Temario