import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import variables from "../../variables.json";
import {
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import axios from "axios";

export const saveAntecedentesPersonales = (data) => {
  return true;
};

export const AntecedentesPersonales = ({
  values,
  setValues,
  handleNext,
  handleBack,
  activeStep,
}) => {
  const [discapacidad, setDiscapacidad] = React.useState();

  const [regiones, setRegiones] = useState();
  const [nacionalidades, setNacionalidades] = useState();
  const [comunas, setComunas] = useState();
  const { handleSubmit, control, reset, watch } = useForm();

  const handleChangeDiscapacidad = (event) => {
    setDiscapacidad(event);
  };

  useEffect(() => {
    var token = sessionStorage.getItem('token');
    axios.get(variables.api + "/api/Region", { headers: {"Authorization" : `Bearer ${token}`} }).then((response) => {
      setRegiones(response.data);
    });

    axios.get(variables.api + "/api/Nacionalidad", { headers: {"Authorization" : `Bearer ${token}`} }).then((response) => {
      setNacionalidades(response.data)
    })
    reset(watch());
  }, []);

  const handleChangeRegion = (r) => {
    var token = sessionStorage.getItem('token');
    axios
      .get(variables.api + "/api/Comuna?id=" + r, { headers: {"Authorization" : `Bearer ${token}`} })
      .then((response) => {
        setValues((prevState) => ({
          ...prevState, region: r
        }))
        setComunas(response.data);
      })
      .catch((e) => {
        alert("error");
      });
  };

  const onSubmit = (data) => {
    console.log(data);
    setValues((prevState) => ({
      ...prevState,
      nombres: data.nombres,
      apellidoPaterno: data.paterno,
      apellidoMaterno: data.materno,
      estadoCivil: parseInt(data.estadoCivil),
      region: values.region,
      comuna: data.comunaResidencia,
      direccion: data.direccion,
      telefono: data.telefono,
      fechaNacimiento: data.fechaNacimiento,
      discapacidad: data.discapacidad,
      sexo: data.sexo,
      familiar: data.familiar,
      funcionario: data.funcionarioFFAA,
      nacionalidad: parseInt(data.nacionalidad)
    }));
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="nombres"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <TextField
            className="m-1 col-12 col-lg-6"
            label="Nombres"
            value={value}
            onChange={onChange}
            required
          />
        )}
      />

      <Controller
        name="paterno"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <TextField
            className="m-1 col-12 col-lg-6"
            label="Apellido Paterno"
            value={value}
            onChange={onChange}
            required
          />
        )}
      />

      <Controller
        name="materno"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <TextField
            className="m-1 col-12 col-lg-6"
            label="Apellido Materno"
            value={value}
            onChange={onChange}
            required
          />
        )}
      />
      <br />
      <Controller
        name="sexo"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FormControl className="m-1 form-control col-12 col-lg-6">
            <InputLabel>Género</InputLabel>
            <Select value={value} onChange={onChange} required>
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              <MenuItem value={"FEMENINO"}>FEMENINO</MenuItem>
              <MenuItem value={"MASCULINO"}>MASCULINO</MenuItem>
              <MenuItem value={"NO BINARIO"}>NO BINARIO</MenuItem>
            </Select>
          </FormControl>
        )}
      ></Controller>
      <br />
      <Controller
        name="estadoCivil"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FormControl className="m-1 col-12 col-lg-6">
            <InputLabel>Estado Civil</InputLabel>
            <Select value={value} onChange={onChange} required>
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              <MenuItem value={1}>Soltero</MenuItem>
              <MenuItem value={2}>Casado</MenuItem>
              <MenuItem value={3}>Viudo</MenuItem>
              <MenuItem value={4}>Unión Civil</MenuItem>
              <MenuItem value={5}>Separado</MenuItem>
            </Select>
          </FormControl>
        )}
      />

    <Controller
        name="nacionalidad"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FormControl className="m-1  col-12  col-lg-6">
            <InputLabel>Nacionalidad</InputLabel>
            <Select
              value = {value}
              onChange={onChange}
              required
            >
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              {nacionalidades &&
                nacionalidades.map((r) => {
                  return (
                    <MenuItem key={r.idPais} value={r.idPais}>
                      {r.gentilicio}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      ></Controller>

      <Controller
        name="region"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FormControl className="m-1  col-12  col-lg-6">
            <InputLabel>Región</InputLabel>
            <Select
              value = {values.region}
              onChange={(e) => onChange(handleChangeRegion(e.target.value))}
              required
            >
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              {regiones &&
                regiones.map((r) => {
                  return (
                    <MenuItem key={r.idRegion} value={r.idRegion}>
                      {r.nombre}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      ></Controller>

      <Controller
        name="comunaResidencia"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FormControl className="m-1 col-12 col-lg-6">
            <InputLabel>Comuna de Residencia</InputLabel>
            <Select value={value} onChange={onChange} required>
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              {comunas &&
                comunas.map((c) => {
                  return (
                    <MenuItem key={c.idComuna} value={c.idComuna}>
                      {c.nombre}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      ></Controller>

      <Controller
        name="direccion"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <TextField
            className="m-1 col-12 col-lg-6"
            label="Dirección"
            value={value}
            onChange={onChange}
            required
          />
        )}
      />

      <Controller
        name="telefono"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <TextField
            className="m-1 col-12 col-lg-6"
            label="Teléfono"
            value={value}
            onChange={onChange}
            required
          />
        )}
      />

      <Controller
        name="funcionarioFFAA"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FormControl className="m-1 col-12 col-lg-6">
            <InputLabel>Es funcionario de las FF.AA</InputLabel>
            <Select value={value} onChange={onChange} required>
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              <MenuItem value="SI">SI</MenuItem>
              <MenuItem value="NO">NO</MenuItem>
            </Select>
          </FormControl>
        )}
      ></Controller>


<Controller
        name="familiar"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FormControl className="m-1 col-12 col-lg-6">
            <InputLabel>¿Tienes algún familiar en la institución?</InputLabel>
            <Select value={value} onChange={onChange} required>
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              <MenuItem value="SI">SI</MenuItem>
              <MenuItem value="NO">NO</MenuItem>
            </Select>
          </FormControl>
        )}
      ></Controller>

      <Controller
        name="fechaNacimiento"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <TextField
            className="m-1 col-12 col-lg-6"
            label="Fecha de Nacimiento"
            type="date"
            value={value}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            required
          />
        )}
      />
      <br />
      <FormControl className="m-1 form-control col-lg-5 col-12">
        <FormControlLabel
          control={
            <Switch
              checked={values.discacpacidad}
              onChange={(e) => handleChangeDiscapacidad(e.target.checked)}
              name="checkedA"
              color="primary"
            />
          }
          label="Posee alguna discapacidad"
        />
      </FormControl>
      <br />
      {discapacidad && (
        <Controller
          name="discapacidad"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              className="m-1 col-12 col-lg-6"
              label="Indiquela"
              value={value}
              onChange={onChange}
            />
          )}
        />
      )}

      <div className="mt-3"></div>
      <Button type="submit" variant="contained" color="primary">
        Siguiente
      </Button>
    </form>
  );
};
