import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@material-ui/core/locale";
import { GoogleOAuthProvider } from '@react-oauth/google';

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";


const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
      secondary: {main: "#7a8691", light: '#7a8691', dark: "#7a8691"}
    },
  },
  esES
);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "50px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

ReactDOM.render(
  <GoogleOAuthProvider clientId="855828938729-ccebri29jt3e382sbamote474s0t99r9.apps.googleusercontent.com">
  <ThemeProvider theme={theme}>
    <BrowserRouter basename={baseUrl}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Routes />
      </AlertProvider>
    </BrowserRouter>
  </ThemeProvider>
  </GoogleOAuthProvider>,
  rootElement
);


