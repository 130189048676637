import axios from "axios";
import React, { useEffect, useState, Component } from "react";
import { useGlobalContext } from "../../context/ContextGlobal";
import variables from "../../variables.json";
import { DataGrid, esES, GridOverlay } from "@mui/x-data-grid";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddAcademico from './AddAcademico';
import { useAlert } from 'react-alert'

import { Tooltip } from "@material-ui/core";
import { compose } from "@mui/system";

const NoData = () => {
  return (
    <GridOverlay>
      <h3>No ha cargado información académica</h3>
    </GridOverlay>
  );
};

const ModificaAcademicos = () => {
  const [antecedentesAcademicos, setAntecedentesAcademicos] = useState();
  const { setLoad } = useGlobalContext();
  const alert = useAlert();

  useEffect(() => {
    setLoad(true);
    const usuario = sessionStorage.getItem("usuario");
    var token = sessionStorage.getItem('token');
    axios
      .get(variables.api + "/api/Cuenta/DatosAcademicos/" + usuario, { headers: {"Authorization" : `Bearer ${token}`} })
      .then((response) => {
        setAntecedentesAcademicos(response.data);
        setLoad(false);
      })
      .catch((e) => {
        console.log(e);
        setLoad(false);
      });

    return () => {
      console.log("saliendo");
    };
  }, []);

 
  
  const columns = [
    { field: "id", headerName: "ID", width: 90, flex: 0.5, hide: true },
    {
      field: "tipoEstablecimientoNombre",
      headerName: "Tipo de establecimiento",
      flex: 1,
    },
    {
      field: "casaEstudios",
      headerName: "Establecimiento",
      flex: 1,
    },
    {
      field: "nombreTitulo",
      headerName: "Nombre del Título",
      flex: 1,
    },
    {
      field: "tipoTituloNombre",
      headerName: "Nivel de estudio",
      flex: 1,
    },

    {
      field: "fechaObtencion",
      headerName: "Fecha de Obtención",
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Acciones",
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            aria-label="delete"
            onClick={(e) => deleteRow(params)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const actualizarDatos = () => {
      setLoad(true);
      let datos = {
          usuario: sessionStorage.getItem("usuario"),
          antecedentesAcademicos
      }
      var token = sessionStorage.getItem('token');
      axios.patch(variables.api + "/api/Cuenta/UpdateAcademico", datos, { headers: {"Authorization" : `Bearer ${token}`} }).then(() => {
          setLoad(false);
          console.log(datos);
          alert.success("Se ha actualizado sus antecedentes académicos")
      }).catch(() => {
          setLoad(false);
          alert.error("Error al actualizar la información")
      });
  }

  const deleteRow = (row) => {
    setLoad(true);
    var lista = antecedentesAcademicos.filter((item) => item.id != row.row.id);
    

  for (var i = 0 ; i < antecedentesAcademicos.length ; i++){

    var campo1 = antecedentesAcademicos[i]["id"];
    var campo2 = row.row.id
    if(campo1 === campo2){

      alert.success("Se elimino el campo de la lista recuerde guardar los cambios");
 
      setAntecedentesAcademicos(antecedentesAcademicos.filter((item) => item.id != row.row.id));
    }else{

    }
  }
  setLoad(false);
  };

  return (
    <div>
      <hr />
      <center>
        <h4>Datos Académicos</h4>
      </center>
      {antecedentesAcademicos ? (       
        <div>
          <div className="mb-3" style={{ height: 400, width: "100%" }}>
            <Tooltip title="Actualiza los cambios realizados ">
                <button onClick={e => actualizarDatos()} className="btn btn-success m-1">Guardar Cambios</button>
            </Tooltip>
            <AddAcademico
              setAntecedentesAcademicos={setAntecedentesAcademicos}
              antecedentesAcademicos={antecedentesAcademicos}
            ></AddAcademico>
            <DataGrid
              localeText={esES.props.MuiDataGrid.localeText}
              rows={antecedentesAcademicos}
              columns={columns}
              pageSize={5}
              components={{
                NoRowsOverlay: NoData,
              }}
            />
          </div>
        </div>
      ) : (
        "cargado..."
      )}
      <hr />
    </div>
  );
};

export default ModificaAcademicos;
