import axios from "axios";
import React, { useState, useEffect } from "react";
import LeyendaEtapa from "../../components/Leyenda";
import variables from "../../variables.json";
import FullScreenDialog2 from "../../components/FullScreenDialog2";
import FullScreenDialog from "../../components/FullScreenDialog";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PerfilCargoDialog from "../PerfilCargoDialog";


const Index = () => {
  const [postulacion, setPostulacion] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [proceso, setProceso] = useState();

  useEffect(() => {
    const usuario = sessionStorage.getItem("usuario");
    var token = sessionStorage.getItem('token');
    axios
      .get(variables.api + "/api/ProcesoPersonal?IdPostulante=" + usuario, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setPostulacion(response.data);
      });
  }, []);

  const verDetalle = (p) => {
    console.log(p);
    setProceso(p);
    setOpenDialog(true);
  }

  const DotProceso = ({ p }) => {

    var color;
    if (p == 'TRUE') {
      color = "success";
    }
    else if (p == 'FALSE') {
      color = "error"
    } else {
      color = "secondary"
    }

    return (
      <TimelineDot color={color} />
    );
  }



  return (
    <div>
      <h1>Mis postulaciones.</h1>
      <LeyendaEtapa></LeyendaEtapa>
      {postulacion.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Nombre Cargo</th>
              <th>Región</th>
              <th>Fecha Postulacion</th>
              <th>Detalle</th>
            </tr>
          </thead>
          <tbody>
            {
              postulacion.map(p => {
                return (
                  <tr key={p.proceso}>
                    <td>{p.nombreCargo}</td>
                    <td>{p.region}</td>
                    <td>{p.fechaPostulacion}</td>
                    <td>
                      {
                        /*
                        <button className="btn btn-primary mb-1" onClick={() => verDetalle(p)}>Ver Detalle</button>
                        
                        */ 
                      }
                      <FullScreenDialog
                        ButtonOpen="Perfil de cargo"
                        Title="Perfil de cargo"
                      >
                        <PerfilCargoDialog
                          data={p.proceso}
                        ></PerfilCargoDialog>
                      </FullScreenDialog>
                    </td>

                  </tr>
                );
              })
            }
          </tbody>

        </table>
      ) : (
        <div className="alert alert-danger">
          <h3>Usted no registra postulaciones a algún cargo.</h3>
        </div>
      )}
      <FullScreenDialog2 open={openDialog} setOpen={setOpenDialog} title={"Detalle Proceso"}>
        {proceso &&
          <Timeline>
            <TimelineItem>
              <TimelineSeparator>
                <DotProceso p={proceso.apruebaFiltro} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Filtro Curricular {proceso.apruebaFiltro === 'FALSE' ? "(No Aprobado)" : proceso.apruebaFiltro === "TRUE" ? "(Aprobado)" : ""}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <DotProceso p={proceso.apruebaExamen} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Exámen Técnico {proceso.apruebaExamen === 'FALSE' ? "(No Aprobado)" : proceso.apruebaExamen === "TRUE" ? "(Aprobado)" : ""}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <DotProceso p={proceso.apruebaEvg} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Evaluación Global {proceso.apruebaEvg === 'FALSE' ? "(No Aprobado)" : proceso.apruebaEvg === "TRUE" ? "(Aprobado)" : ""}</TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <DotProceso p={proceso.apruebaPsicolaboral} />
              </TimelineSeparator>
              <TimelineContent>Evaluación Psicolaboral {proceso.apruebaPsicolaboral === 'FALSE' ? "(No Aprobado)" : proceso.apruebaPsicolaboral === "TRUE" ? "(Aprobado)" : ""}</TimelineContent>
            </TimelineItem>

          </Timeline>
        }

      </FullScreenDialog2>
    </div>
  );
};

export default Index;
