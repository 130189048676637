import React from 'react'

const FinalizarExamen = () => {
    return (
    <div className='container-fluid'>
        <div className="alert alert-success">
            <h4>Estimado postulante ha finalizado su exámen técnico, será notificado mediante correo y por la plataforma de los resultados de esta etapa</h4>
        </div>
    </div>
  )
}

export default FinalizarExamen