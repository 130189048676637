import React, { useEffect, useState } from "react";
import axios from "axios";
import variables from "../../variables.json";
import { useGlobalContext } from "../../context/ContextGlobal";
import Moment from "react-moment";
import { useHistory } from "react-router";
import Temario from "./temario";


export function b64toBlob(b64, onsuccess, onerror) {
  var img = new Image();

  img.onerror = onerror;

  img.onload = function onload() {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(onsuccess);
  };

  img.src = b64;
}




const Index = () => {
  const token = sessionStorage.getItem("token");
  const { setLoad, alertDialog } = useGlobalContext();
  const [examen, setExamen] = useState(null);
  const [temarioSelected, setTemarioSelected] = useState(null);
  const history = useHistory();
  

  useEffect(() => {
    const usuario = sessionStorage.getItem("usuario");
    setLoad(true);

    if(usuario === null){
      history.push("/Login");
    }else{
    axios
      .get(variables.api + "/api/Examen/VerificarExamen/" + usuario, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setExamen(response.data);
      })
      .catch(() =>
        alertDialog("Error", "No se pudo verificar su estado", "error")
      )
      .finally(() => setLoad(false));
    }
  }, []);

  const iniciarExamen = (prueba) => {
    sessionStorage.setItem("prueba", prueba);
    history.push("/IniciarTest");
  };

  const confirmarAsistencia = (prueba) => {
    setLoad(true);
    const usuario = sessionStorage.getItem("usuario");
    var data = {
      postulante: usuario,
      prueba: prueba,
    };
    axios
      .post(variables.api + "/api/Examen/ConfirmarAsistencia", data)
      .then(() => {
        alertDialog(
          "Completado",
          "Se ha confirmado la asistencia al exámen",
          "success"
        );
      })
      .catch(() =>
        alertDialog("Error", "No se pudo confirmar su asistencia", "error")
      )
      .finally(() => setLoad(false));
  };

  const recargar = () => {
    window.location.reload();
  };

  const bajarTemario = (codigoPrueba) => {

    setTemarioSelected(codigoPrueba)

    /* setLoad(true);

    const usuario = sessionStorage.getItem("usuario");

    axios
      .get(variables.api + "/api/Examen/BajarTemario/"+ codigoPrueba+"/"+ usuario )
      .then((response) => {

      })
      .catch(() =>
        alertDialog(
          "Error",
          "No se pudo descargar la información del temario",
          "error"
        )
      )
      .finally(() => setLoad(false)); */
  };

  return (
    <React.Fragment>
    {/*<p>{JSON.stringify(examen)}</p>*/}
      <div className="alert alert-info">
        <h4>
          Estimado postulante en esta sección aparecerán los procesos que se
          habiliten para la etapa de exámenes técnicos
        </h4>
      </div>
      {examen && (
        <>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th>Nombre del proceso</th>
              <th>Temario</th>
              <th>Accion</th>
            </tr>
          </thead>
          <tbody>
            {examen.map((p) => {
              return (
                <tr>
                  <td>{p.nombreCargo}</td>
                  <td>
                    {p.temarioExiste ? (
                      <button
                        className="btn btn-success btn-sm "
                        onClick={() => bajarTemario(p.codigoPrueba)}
                      >
                        Bajar Temario
                      </button>
                    ) : (
                      <span>Proceso no aplica temario</span>
                    )}
                  </td>
                  <td>
                    {p.pruebaHabilitada ? (
                      <button  className="btn btn-primary btn-sm" onClick={() => iniciarExamen(p.codigoPrueba)}>
                        Iniciar Exámen
                      </button>
                    ) : (
                      <button className="btn btn-secondary btn-sm" disabled>
                        Prueba Deshabilitada
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {temarioSelected ? (
        <center>
          <div>
            <Temario data={temarioSelected} onClose={ () => { setTemarioSelected(null) }}></Temario>
          </div>
        </center>
        ): null}
        </>
      )}
      
       
    </React.Fragment>
  );
};

export default Index;
