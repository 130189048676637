import axios from "axios";
import React, { useState, createContext, useContext, useEffect } from "react";
import variables from '../variables.json'

const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const [loged, setLoged] = useState(false);
  const [load, setLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [alertMensaje, setAlertMensaje] = useState('');
  const [alertTitulo, setAlertTitulo] = useState('');
  const [alertType, setAlertType] = useState('');

  //funcion para levantar un dialog
  const alertDialog = (titulo, mensaje, tipo) => {
    setAlertTitulo(titulo);
    setAlertMensaje(mensaje);
    setAlertType(tipo);
    setShowDialog(true);
  }

  //setea una session de usuario
  const setSessionUser = (usuario) => {
    sessionStorage.setItem('usuario' , usuario);
    setLoged(true);
  } 

  //verifica si el usuario esta logeado en sesion
  useEffect(() => {
   
    var data = {
      id: 'e9d69a1b54334da59f7fde53ec76858a',
      secret: '4f9c78e76ec24584bfdc4c643e3ded9c'
    }
 
         axios.post(variables.api + "/api/Auth",data).then(response => {
          sessionStorage.setItem('token',response.data);
        });

 
    var usuario = sessionStorage.getItem('usuario');
    
    if(usuario){
      setLoged(true);
    }
  },[loged])


  const value = {
    loged, //variable para verificar si esta logeado.
    load, //variable para activar el componente backdrop
    setLoged, //setea un usuario logeado
    setLoad, // setea el backdrop
    alertMensaje, //mensaje del alertDialog
    alertTitulo, //titulo del alert dialog
    showDialog, //variable flag para activar el alert dialog
    setShowDialog, //setea apertura o cierre del dialogo
    alertDialog, //funcion para levantar el alert dialog
    alertType, //tipo de alerta
    setSessionUser, //setea el usuario de session.
  };
  return <GlobalContext.Provider value={value} {...props} />;
};
export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("No se encuentra el provider Global");
  }
  return context;
};