import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/ContextGlobal";
import variables from "../../variables.json";
import { DataGrid, esES, GridOverlay } from "@mui/x-data-grid";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddConocimiento from "./AddConocimiento";
import { useAlert } from "react-alert";
import { Tooltip } from "@material-ui/core";


const NoData = () => {
  return (
    <GridOverlay>
      <h3>No ha cargado conocimientos</h3>
    </GridOverlay>
  );
};

const ModificaConocimientos = () => {
  const [conocimientos, setConocimientos] = useState();
  const { setLoad } = useGlobalContext();
  const alert = useAlert();

  useEffect(() => {
    
var token = sessionStorage.getItem('token');
    setLoad(true);
    const usuario = sessionStorage.getItem("usuario");
    axios
      .get(variables.api + "/api/Cuenta/DatosConocimientos/" + usuario, { headers: {"Authorization" : `Bearer ${token}`} })
      .then((response) => {
        setConocimientos(response.data);
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        alert.error("error al cargar la información de conocimientos");
      });

    return () => {
      console.log("saliendo");
    };
  }, []);


  const columns = [
    { field: "id", headerName: "ID", width: 90, flex: 0.5, hide: true },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1
    },
    {
      field: "nivel",
      headerName: "Nivel",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            aria-label="delete"
            onClick={(e) => deleteRow(params)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const deleteRow = (row) => {

    setLoad(true);
    setConocimientos(
      conocimientos.filter((item) => item.id != row.row.id)
    );
    
    for (var i = 0 ; i < conocimientos.length ; i++){

      var campo1 = conocimientos[i]["id"];
      var campo2 = row.row.id
      if(campo1 === campo2){
  
        alert.success("Se elimino el campo de la lista recuerde guardar los cambios");
   
        setConocimientos(conocimientos.filter((item) => item.id != row.row.id));
      }else{
  
      }
    }
    setLoad(false);
  };

  const actualizarDatos = () => {
    
var token = sessionStorage.getItem('token');
    setLoad(true);
    let datos = {
        usuario: sessionStorage.getItem("usuario"),
        conocimientos
    }
    console.log(datos);
    axios.patch(variables.api + "/api/Cuenta/UpdateConocimientos", datos, { headers: {"Authorization" : `Bearer ${token}`} }).then(() => {
        setLoad(false);
        alert.success("Se ha actualizado sus conocimientos")
    }).catch(() => {
        setLoad(false);
        alert.error("Error al actualizar la información")
    });
}

  return <div>
      {
        conocimientos ? 
        <React.Fragment>
        <div className="mb-3" style={{ height: 400, width: "100%" }}>
        <Tooltip title="Actualiza los cambios realizados ">
              <button
                onClick={(e) => actualizarDatos()}
                className="btn btn-success m-1"
              >
                Guardar Cambios
              </button>
            </Tooltip>
        <AddConocimiento
                setConocimiento={setConocimientos}
              ></AddConocimiento>
          <DataGrid
            localeText={esES.props.MuiDataGrid.localeText}
            rows={conocimientos}
            columns={columns}
            pageSize={5}
            components={{
              NoRowsOverlay: NoData,
            }}
          />
        </div>
      </React.Fragment>: 'cargando ...'

      }
      
  </div>;
};

export default ModificaConocimientos;
