import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import ValidaRut from "../../components/ValidaRut";
import { useForm, Controller } from "react-hook-form";
import {Button} from '@material-ui/core'
import axios from "axios";
import variables from '../../variables.json';
import { useAlert } from 'react-alert'


const LostAccount = () => {
  const { handleSubmit, control } = useForm();
  const [rutValido, setRutValido] = useState(false);
  const alert = useAlert(); 

  const onSubmit = (data) => {
      var token = sessionStorage.getItem('token');
      var datos = {
          rut: data.rut
      }
      axios.patch(variables.api + "/api/Cuenta",datos, { headers: {"Authorization" : `Bearer ${token}`} }).then(e => {
          alert.success("Su solicitud ha sido procesada, verifique su correo para restablecer su contraseña");
      }).catch(() => {
          alert.error("Hubo un error al procesar su solicitud.");
      });
  }

  return (
    <div>
      <div className="alert alert-info">
        <h4>
          Para recuperar su contraseña debera indicar su número de RUN y se
          enviará un enlace a su correo electronico.
        </h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <center>
      <Controller
        name="rut"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <ValidaRut value={value} onChange={onChange} onValid={setRutValido}>
            <TextField
              label=""
              className="m-1 col-lg-4"
              required
            ></TextField>
          </ValidaRut>
        )}
      />
      {!rutValido && <p className="text-danger">RUN Inválido</p>}
      <br/>
      <Button type="submit" variant="contained" color="primary">Recuperar Contraseña</Button>
      </center>
      </form>
      
    </div>
  );
};

export default LostAccount;
