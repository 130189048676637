import React from 'react'

const Deshabilitado = () => {
    return (
        <div className='container-fluid'>
            <div className="alert alert-danger">
                <h4>Estimado postulante se ha deshabilitado su exámen técnico, para mayor información contacte con el área de selección</h4>
            </div>
        </div>
      )
}

export default Deshabilitado