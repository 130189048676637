import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/ContextGlobal";
import variables from "../../variables.json";
import { DataGrid, esES, GridOverlay } from "@mui/x-data-grid";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddLaboral from "./AddLaboral";
import { useAlert } from "react-alert";

import { Tooltip } from "@material-ui/core";

const NoData = () => {
  return (
    <GridOverlay>
      <h3>No ha cargado información laboral</h3>
    </GridOverlay>
  );
};

const ModificaLaborales = () => {
  const [antecedentesLaborales, setAntecedentesLaborales] = useState();
  const { setLoad } = useGlobalContext();
  const alert = useAlert();

  useEffect(() => {
    var token = sessionStorage.getItem('token');
    setLoad(true);
    const usuario = sessionStorage.getItem("usuario");
    axios
      .get(variables.api + "/api/Cuenta/DatosLaborales/" + usuario, { headers: {"Authorization" : `Bearer ${token}`} })
      .then((response) => {
        setAntecedentesLaborales(response.data);
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        alert.error("error al cargar la información laboral");
      });

    return () => {
      console.log("saliendo");
    };
  }, []);

  const deleteRow = (row) => {
    setLoad(true);
    setAntecedentesLaborales(
      antecedentesLaborales.filter((item) => item.id != row.row.id)
    );
   

    for (var i = 0 ; i < antecedentesLaborales.length ; i++){

      var campo1 = antecedentesLaborales[i]["id"];
      var campo2 = row.row.id
      if(campo1 === campo2){
  
        alert.success("Se elimino el campo de la lista recuerde guardar los cambios");
   
        setAntecedentesLaborales(antecedentesLaborales.filter((item) => item.id != row.row.id));
      }else{
  
      }
    }
    setLoad(false);


  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, flex: 0.5, hide: true },
    {
      field: "empresa",
      headerName: "empresa",
      flex: 1,
    },
    {
      field: "descripcionCargo",
      headerName: "Cargo",
      flex: 1,
    },
    {
      field: "desde",
      headerName: "desde",
      type: "date",
      flex: 1,
    },
    {
      field: "hasta",
      headerName: "hasta",
      type: "date",
      flex: 1,
    },

    {
      field: "referencia",
      headerName: "referencia",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Acciones",
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            aria-label="delete"
            onClick={(e) => deleteRow(params)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];


  const actualizarDatos = async() => {
    var token = await sessionStorage.getItem('token');
    setLoad(true);
    const datos = {
        usuario: sessionStorage.getItem("usuario"),
        antecedentesLaborales
    }
    axios.patch(variables.api + "/api/Cuenta/UpdateLaboral", datos, { headers: {"Authorization" : `Bearer ${token}`} }).then(() => {
        setLoad(false);
        alert.success("Se ha actualizado sus antecedentes laborales")
    }).catch(() => {
        setLoad(false);
        alert.error("Error al actualizar la información")
    });
}

  return (
    <div>
      <hr />
      <center>
        <h4>Datos Laborales</h4>
      </center>
      {antecedentesLaborales ? (
        <div>
          <div className="mb-3" style={{ height: 400, width: "100%" }}>
            <Tooltip title="Actualiza los cambios realizados ">
              <button
                onClick={(e) => actualizarDatos()}
                className="btn btn-success m-1"
              >
                Guardar Cambios
              </button>
            </Tooltip>
            <AddLaboral
              setAntecedentesLaborales={setAntecedentesLaborales}
              antecedentesLaborales={antecedentesLaborales}
            ></AddLaboral>
            <DataGrid
              localeText={esES.props.MuiDataGrid.localeText}
              rows={antecedentesLaborales}
              columns={columns}
              pageSize={5}
              components={{
                NoRowsOverlay: NoData,
              }}
            />
          </div>
        </div>
      ) : (
        "cargado..."
      )}
      <hr />
    </div>
  );
};

export default ModificaLaborales;
