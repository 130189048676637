import React, { useEffect, useState } from "react";
import axios from "axios";
import variables from "../../variables.json";
import { useGlobalContext } from "../../context/ContextGlobal";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";


const Examen = ({ codigo, postulante }) => {
  const [pregunta, setPregunta] = useState(null);
  const { alertDialog, setLoad } = useGlobalContext();
  const [correlativo, setCorrelativo] = useState(1);
  const [preguntas, setPreguntas] = useState([]);
  const [opcionSelecionada, setOpcionSeleccionada] = useState();
  const [finaliza, setFinaliza] = useState(false);
  const history = useHistory();

  useEffect(() => {
    constructor(1);
  }, [postulante]);

  const constructor = (c) => {
    setLoad(true);
    const data = {
      postulante: postulante,
      prueba: codigo,
      correlativo: c,
    };

    axios
      .patch(variables.api + "/api/Examen/CountPregunta", data)
      .then((response) => {

        setPreguntas(response.data);
      })
      .catch(() =>
        alertDialog(
          "Error",
          "Error al rescatar la información del exámen",
          "error"
        )
      )
      .finally(() => setLoad(false));
    setLoad(true);
    axios
      .patch(variables.api + "/api/Examen", data)
      .then((response) => {
        if(response.data.length == 0){
          return false;
        }
        setPregunta(response.data);
        var selecionada = response.data.find((x) => x.respuesta);

        if (selecionada) {
          setOpcionSeleccionada(selecionada.respuesta);
        }
      })
      .catch(() =>
        alertDialog(
          "Error",
          "Error al rescatar la información del exámen",
          "error"
        )
      )
      .finally(() => setLoad(false));
  };

  const cambiarOpcion = (o) => {
    var opciones = [...pregunta];
    var largo = opciones.length;

    for (var x = 0; x <= largo - 1; x++) {
      if (opciones[x].opcionId == o.opcionId) {
        opciones[x].respuesta = o.opcionId;
      } else {
        opciones[x].respuesta = null;
      }
    }

    setPregunta(opciones);
    setOpcionSeleccionada(o.opcionId);
  };

  const seleccionarPregunta = (p, c) => {
    const data = {
      postulante: postulante,
      prueba: codigo,
      correlativo: c,
    };

    setLoad(true);
    axios
      .patch(variables.api + "/api/Examen", data)
      .then((response) => {
        setPregunta(response.data);
        var selecionada = response.data.find((x) => x.respuesta);

        if (selecionada) {
          setOpcionSeleccionada(selecionada.respuesta);
        }
        setCorrelativo(c);
        setFinaliza(false);
      })
      .catch(() =>
        alertDialog(
          "Error",
          "Error al rescatar la información del exámen",
          "error"
        )
      )
      .finally(() => setLoad(false));
  };

  const grabarRespuesta = async (p) => {
    if (opcionSelecionada == undefined || opcionSelecionada == null) {
      alertDialog("Error", "Debe seleccionar una opción", "error");
      return false;
    }

    const data = {
      postulante: postulante,
      prueba: codigo,
      pregunta: p,
      opcion: opcionSelecionada,
    };
    setLoad(true);
    axios
      .post(variables.api + "/api/Examen/GrabarRespuesta", data)
      .then((response) => {
        
        if(response.data.habilitado == false){
          history.push("/Examen/Deshabilitado");
        }

        if(response.data.suspendido == true){
          history.push("/Examen/Suspendido");
        }

        if(response.data.finalizado == true){
          history.push("/Examen/Expira");
        }

        if(response.data.finalizaExamen == true){
          history.push("/Examen/FinalizarExamen");
        }
        setOpcionSeleccionada(null);
        
        var aux = correlativo + 1;

        constructor(aux);
        if (preguntas.length + 1 == aux) {
          setFinaliza(true);
        } else {
          setCorrelativo(aux);
        }
      })
      .catch(() => {
        alertDialog("Error", "No se pudo grabar su respuesta", "error");
      })
      .finally(() => setLoad(false));
  };

  const finalizarExamen = () => {
    setLoad(true);
    const data = {
      postulante: postulante,
      prueba: codigo,
    };

    axios
      .post(variables.api + "/api/Examen/FinalizarExamen", data)
      .then(() => {
        history.push("/Examen/FinalizarExamen");
      })
      .catch(() =>
        alertDialog(
          "error",
          "error de comunicación, vuelva a intentarlo",
          "error"
        )
      )
      .finally(() => setLoad(false));
  };

  return (
    <div>
      <div className="opcion-container">
        
        {preguntas &&
          preguntas.map((p) => {
            var respondida = p.resultado ? "respondida" : "";
            var active = p.correlativo == correlativo ? "pregunta-activa" : "";
            return (
              <span
                key={p.pregunta}
                className={"opcion-btn " + respondida + " " + active}
                onClick={() => seleccionarPregunta(p.pregunta, p.correlativo)}
              >
                {p.correlativo}
              </span>
            );
          })}
      </div>
      <br />

      {finaliza ? (
        <React.Fragment>
          <div className="alert alert-warning">
            <h5>
              Estimado postulante, verifique que ha respondido todas las
              preguntas, puede cambiar alguna respuesta, al momento de presionar
              "Finalizar Exámen" ya no podrá volver atras.{" "}
            </h5>
          </div>
          <button className="btn btn-primary" onClick={() => finalizarExamen()}>
            Finalizar Exámen
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {pregunta &&  (
            <div>
              
              <div className="card">
                <div className="card-header">
                  {ReactHtmlParser(pregunta[0].pregunta)}
                </div>
                <div className="card-body">
                  {pregunta.map((p) => {
                    var chequed =
                      p.respuesta == p.opcionId.toUpperCase() ? true : false;

                    return (
                      <React.Fragment key={p.opcionId}>
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            onChange={() => cambiarOpcion(p)}
                            defaultChecked={chequed}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            <b>{p.opcionCorrelativo}</b> {p.opcion}
                          </label>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="card-footer">
                  <button
                    className="btn btn-primary"
                    onClick={() => grabarRespuesta(pregunta[0].codigoPregunta)}
                  >
                    Grabar Respuesta
                  </button>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Examen;
