import React, { useEffect, useState } from 'react'
import "./Footer.css";
import LocationCityIcon from "@material-ui/icons/PinDrop";
import EmailICon from "@material-ui/icons/Email";
import VisitedIcon from "@material-ui/icons/Visibility";
import variables from "../variables.json";
import axios from 'axios';

const Footer = () => {
    const [visitas, setVisitas] = useState(0);

    useEffect(() => {
        var token = sessionStorage.getItem('token');
        axios
            .post(variables.api + "/api/Visitas", { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setVisitas(response.data);
            });
    }, []);

    return (
        <div className="pdi-footer">
            
            <div className="pdi-footer-content">
                <div>
                    <VisitedIcon /> Visitas {visitas}
                </div>
                <div>
                    <LocationCityIcon/> GENERAL MACKENNA 1370
                </div>
            </div>
        </div>
    )
}

export default Footer