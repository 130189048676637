import React, { useState, useEffect } from "react";
import Documentacion from "../../components/Documentacion";
import { useGlobalContext } from "../../context/ContextGlobal";
import axios from 'axios';
import { useAlert } from "react-alert";
import variables from '../../variables.json';

const ModificaDocumentacion = () => {
  const [archivo, setArchivo] = useState(null);
  const [usuario, setUsuario] = useState();
  const alert = useAlert();
  const { setLoad } = useGlobalContext();

  const estilo = {
    height: "900px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    setUsuario(sessionStorage.getItem("usuario"));
    var token = sessionStorage.getItem('token');

  }, []);

  const actualizarDocumento = () => {
    var token = sessionStorage.getItem('token');
    const formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("usuario", usuario);
    
    var token = sessionStorage.getItem('token');
    axios
      .post(variables.api + "/api/Cuenta/UpdateDocumentacion", formData, { headers: {"Authorization" : `Bearer ${token}`} })
      .then(() => {
        alert.success("Se ha actualizado su documentación");
      })
      .catch(() => alert.error("Error al actualizar la información"));
  };

  return (
    <div className="m-3">
      <input
        onChange={(e) => setArchivo(e.target.files[0])}
        type="file"
        className="form-control"
      />
      <button className="btn btn-primary mb-5" onClick={e => actualizarDocumento()}>
        Reemplazar Documento
      </button>
  
        <center>
          <div style={estilo}>
            <Documentacion data={usuario}></Documentacion>
          </div>
        </center>
      
    </div>
  );
};

export default ModificaDocumentacion;
