import React, { useState, useEffect} from 'react';
import axios from 'axios';
import variables from '../variables.json';

const Documentacion = ({data}) => {
    const [archivo, setArchivo] = useState();

    useEffect(() => {
        var token = sessionStorage.getItem('token');
        axios.get(variables.api + "/api/Cuenta/GetDocumentacion/"+data, { headers: {"Authorization" : `Bearer ${token}`} }).then(e => {
                setArchivo("data:application/pdf;base64," + e.data);    
            }
        ).catch(() => {
            console.log("Error","Error de comunicación con la base de datos, no se pudo obtener la documentacion del postulante","error")
        })
        
    }, [data])
    return (      
             archivo?
                <embed
                src={archivo}
                type="application/pdf"
                width="100%"
                height="100%"
              />:null
    )
}

export default Documentacion
