import axios from 'axios';
import React , {useState}from 'react'
import { useParams } from 'react-router';
import variables from '../../variables.json';
import { useAlert } from 'react-alert'



const RecoverPassword = () => {
    const [showButton, setShowButton] = useState(false);
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const id = useParams();
    const alert = useAlert();
    const checkPasswordEquals = (e) => {
        setRePassword(e);
        if(password.trim() === e.trim() && e.length >= 8){
            setShowButton(true);
        }else{
            setShowButton(false);
        }
    }


    const actualizarContraseña = () => {
        var datos = {
            password: password,
            id: id.id
        }
        var token = sessionStorage.getItem('token');
        axios.put(variables.api + "/api/Cuenta",datos, { headers: {"Authorization" : `Bearer ${token}`} }).then(response => {
            if(response.data === 0){
                alert.error("su codigo ya ha sido utilizado");
            }else{
                alert.success("Su contraseña ha sido cambiada");
            }
        }).catch(() => {
            alert("Error en la transacción, vuelva intentarlo.");
        })
    }

    return (
        <div>
            <center>
                <h3>Bienvenido Postulante</h3>
                <div className="alert alert-info col-6 col-lg-4 col-xl-3 col-md-5">
                    <ol>
                        <li>Debe ingresar las dos contraseñas identicas</li>
                        <li>La contraseña debe ser de un largo mayor o igual a 8 caracteres</li>
                    </ol>
                </div>
                <label>Ingrese su nueva contraseña</label>
                <input value={password} onChange={e => setPassword(e.target.value)} className="form-control col-6 col-lg-4 col-xl-3 col-md-5" type="password" />
                <label>Repita contraseña</label>
                <input value={rePassword} onChange={e => checkPasswordEquals(e.target.value)} className="form-control col-6 col-lg-4 col-xl-3 col-md-5" type="password" />
                {
                    showButton ?
                    <button onClick={e => actualizarContraseña()} className="btn btn-primary m-3">Guardar</button>
                    :
                    <button disabled className="btn btn-secondary m-3">Guardar</button>
                }
            </center>
        </div>
    )
}

export default RecoverPassword
