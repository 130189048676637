
import React from 'react'
import StepperAccount from './StepperAccount';

const New = () => {
    return (
        <div>
            <StepperAccount></StepperAccount>              
        </div>
    )
}

export default New
